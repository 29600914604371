import React, { useEffect, useContext, useState } from 'react'
import { Flex, Box, Button, Text } from 'theme-ui'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import styled from "styled-components"
import InputMask from 'react-input-mask';
import httpServiceToken from '../../../../../../site/src/service/httpServiceToken'
// import { getPricing, setKtTokenSifre } from '../../../../../../site/src/service/auth'
import { FormContext } from '@solid-ui-components/ContentForm'
import useForm from '@helpers/useForm'


const StyledTextInput = styled.div`
color: #343a40;

label {
  display: inline;
}

input {
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  outline: none;
  border: 1px solid #ebecee;
  padding: 10px;
  margin: 10px 0;
}

input:focus {
  border-color: #64b5f6;
}
`;

const StyledMaskInput = styled.div`
color: #343a40;

label {
  display: inline;
}

input {
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  outline: none;
  border: 1px solid #ebecee;
  padding: 10px;
  margin: 10px 0;
}

input:focus {
  border-color: #64b5f6;
}
`;

const StyledCardForm = styled.div`
flex: 1 0 auto;
background-color: #e0e0e0;
border-radius: 8px;
max-width: 550px;
overflow: hidden;
padding: 1em 2em;
box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.5);

h2 {
  color: #343a40;
  margin: 0;
  padding-top: .25em;
  border-bottom: 1px solid #aeaeae;
  padding-bottom: .75em;
}

ul {
  list-style: none;
  padding: 0;
   
  li:not(:last-child) {
    margin-bottom: 15px;
 
  }
}
`;



const TextNumberInput = ({ label, type = "text", id, value, odiv, ...props }) => (
    <StyledTextInput>
        {label && <label className='crd-lbl' htmlFor={id}>{label}</label>}
        <input id={id} type={type} value={value} onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
            }
        }}  {...props} className={odiv} />
    </StyledTextInput>
);

const TextInput = ({ label, type = "text", id, value, ...props }) => (
    <StyledTextInput>
        {label && <label className='crd-lbl' htmlFor={id}>{label}</label>}
        <input id={id} type={type} value={value}   {...props} />
    </StyledTextInput>
);



const MaskInput = ({ label, type = "text", id, value, mask, ...props }) => (
    <StyledMaskInput>
        {label && <label htmlFor={id}>{label}</label>}
        <InputMask mask={mask} id={id} type={type} value={value} {...props} />
    </StyledMaskInput>
);
const initialState = {
    name: "BOX CAD",
    number: "",
    expiryMonth: "",
    expiryYear: "",
    cvv: ""
};


const CardFormBlock01 = ({
    cardInfo: { name, number, expiryMonth, expiryYear, cvv },
    paket,
    onChange,
    content: { dt },
    mesafeli,
    kulId
}) => {

    const { handleSubmit, submitting, success } = useForm()
    const { formValues, setFormValues } = useContext(FormContext)

    const [odemeMesaj, setodemeMesaj] = useState("");

    const [seciliTaksit, setseciliTaksit] = useState(1);

    const [pktFiyat, setpktFiyat] = useState("0");



    const onSubmit = dt => async e => {

        e.preventDefault();
        setodemeMesaj("");
        if (mesafeli == false) {
            setodemeMesaj("Mesafeli satış sözleşmesini ve iade koşullarını okuduğunuz belirtmelisiniz");
            return;
        }

        const krediKartBilgi = {};
        var name = document.getElementById("name").value;
        var number = document.getElementById("number").value;
        var expiryMonth = document.getElementById("expiryMonth").value;
        var expiryYear = document.getElementById("expiryYear").value;
        var cvv = document.getElementById("cvv").value;

        // const paket = await getPricing();

        krediKartBilgi.KartSahibi = name;
        krediKartBilgi.KartNo = number;
        krediKartBilgi.Ay = expiryMonth;
        krediKartBilgi.Yil = "20" + expiryYear;
        krediKartBilgi.Cvv = cvv;
        krediKartBilgi.SeciliTaksit = seciliTaksit.toString();
        krediKartBilgi.Paket = paket;
        krediKartBilgi.DonusUrl = "iyziDonus";
        krediKartBilgi.Platform = "1";
        krediKartBilgi.HtmlSavePath = "";
        krediKartBilgi.KullaniciId=kulId;
 
           
        if (parseInt(expiryMonth) < 0 || parseInt(expiryMonth) > 12) {
            setodemeMesaj("Lütfen geçerli bir ay bilgisi giriniz");
            return;
        }

        let nowDt = new Date();
        if (parseInt(krediKartBilgi.Yil) < nowDt.getFullYear()) {
            setodemeMesaj("Lütfen geçerli bir yil bilgisi giriniz");
            return;
        }


        httpServiceToken.post("UnLocked/IyzicoKartCekMobil", { params: krediKartBilgi }).then((res) => {
            if (res.DogruMu) {
                // setKtTokenSifre(res.Refresh);
                window.location.assign(res.Data);

            } else {
                setodemeMesaj(res.Mesaj);
                // alert( res.Mesaj);
            }
        }).catch((err) => {
            setodemeMesaj("Bir hata oluştu lütfen canlı destek hattından bilgi işlem yetkilisi ile görüşünüz veya bilgileri gözden geçirerek tekrar deneyiniz");
            //console.log(err);;
        });
    }
    const formId = "frmOdeme"
    const [taksitListesi, settaksitListesi] = useState([])


    async function taksitList() {
        const krediKartBilgi = {};
        var name = document.getElementById("name").value;
        var number = document.getElementById("number").value;
        var expiryMonth = document.getElementById("expiryMonth").value;
        var expiryYear = document.getElementById("expiryYear").value;
        var cvv = document.getElementById("cvv").value;

        // const paket = await getPricing();

        krediKartBilgi.KartSahibi = name;
        krediKartBilgi.KartNo = number;
        krediKartBilgi.Ay = 12;
        krediKartBilgi.Yil = 2022;
        krediKartBilgi.Cvv = cvv;
        krediKartBilgi.SeciliTaksit = "1";
        krediKartBilgi.Paket = paket;
        krediKartBilgi.DonusUrl = "iyziDonus";
        krediKartBilgi.Platform = "1";
        krediKartBilgi.HtmlSavePath = "";
        if (number != '')
            httpServiceToken.post("UnLocked/IyzicoTaksitSorguMobil", { params: krediKartBilgi}).then((res) => {
                if (res.DogruMu) {
                    settaksitListesi(res.Data);
                } else {
                    settaksitListesi([]);
                }

            }).catch((err) => {
                settaksitListesi([]);
            });
        else {
            settaksitListesi([]);
        }
    }


    useEffect(() => {
        return () =>
            success !== undefined &&
            submitting === false &&
            setFormValues({
                ...formValues,
                [formId]: {}
            })
    }, [success, submitting, setFormValues])

    const handleChange = e => {
        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [formId]: {
                ...formValues?.[formId],
                [name]: value,

            }

        })
        taksitListesi.forEach(tkst => {
            if (tkst.Id == parseInt(value)) {
                tkst.SeciliMi = true;
                setseciliTaksit(value);
            }
            else {
                tkst.SeciliMi = false;
            }
        });

    };

    useEffect(async () => {
        if (typeof window !== 'undefined') {
            const oinputs = document.getElementById("number");
            if (oinputs) {
                oinputs.addEventListener('focusout', () => {
                    taksitList();
                });
            }
        }

        // const paket = await getPricing();
        if (paket)
            setpktFiyat(paket.Tutar + ".00 ₺");

    }, []);

    return (
        <form onSubmit={onSubmit(dt)}
            method='POST'>
            <StyledCardForm sx={{}}>

                <Flex
                    sx={{
                        alignItems: `start`,
                        justifyContent: `start`
                    }}
                > <Box sx={{ p: 1, width: "50%" }}>
                        <TextInput
                            label="Kart Sahibi"
                            id="name"
                            type="text"
                            value={name}
                            placeholder="Ad Soyad"
                            onChange={e => onChange({ key: "name", value: e.target.value })}
                            minLength="1"
                            maxLength="40"
                            required
                            autoFocus
                        />
                    </Box>
                    <Box sx={{ p: 1, width: "50%" }}>
                        <TextNumberInput
                            label="Kart Numarası"
                            id="number"
                            type="text"
                            value={number}
                            onChange={e => onChange({ key: "number", value: e.target.value })}
                            placeholder="XXXX XXXX XXXX XXXX"
                            minLength="16"
                            maxLength="16"
                            // onChange={handleChange}
                            required
                            odiv="kart"
                        />
                    </Box>
                </Flex>
                <Flex
                    sx={{
                        alignItems: `start`,
                        justifyContent: `start`
                    }}  >
                    <Box sx={{ p: 1, width: "33%" }}>
                        <TextNumberInput
                            label="Ay"
                            id="expiryMonth"
                            type="text"
                            value={expiryMonth}
                            onChange={e =>
                                onChange({ key: "expiryMonth", value: e.target.value })
                            }
                            minLength="2"
                            maxLength="2"
                            required
                        />
                    </Box>
                    <Box sx={{ p: 1, width: "33%"}}>
                        <TextNumberInput
                            label="Yıl"
                            id="expiryYear"
                            type="text"
                            value={expiryYear}
                            onChange={e =>
                                onChange({ key: "expiryYear", value: e.target.value })
                            } 
                            minLength="2"
                            maxLength="2"
                            required
                        />
                    </Box>
                    <Box sx={{ p: 1, width: "33%" }}>
                        <TextNumberInput
                            label="CVV"
                            id="cvv"
                            type="text"
                            value={cvv}
                            onChange={e => onChange({ key: "cvv", value: e.target.value })}
                            minLength="3"
                            maxLength="4"
                        />
                    </Box>
                </Flex>

                <Flex
                    sx={{
                        alignItems: `start`,
                        justifyContent: `start`
                    }}
                >

                    <table className='tkst-tbl'>
                        <tbody>
                            {
                                taksitListesi.map(
                                    ({ Taksit, Tutar, Id, SeciliMi }, k) => (
                                        <tr key={"myRadio" + k} ><td>   <form name="myForm">
                                            <input type="radio" name={"myRadio" + k} value={Id}
                                                checked={SeciliMi === true}
                                                onChange={handleChange} />
                                        </form></td><td>{Taksit}  </td><td > {pktFiyat == Tutar ? (
                                            // <Box sx={{
                                            //     boxSizing: "border-box",
                                            //     margin: "0px",
                                            //     display: "inline-block",
                                            //     verticalAlign: "middle",
                                            //     borderRadius: "4pt",
                                            //     minWidth: "auto",
                                            //     color: "beta",
                                            //     bg: "betaLight"
                                            // }}>{Tutar}</Box>
                                            <Text color="beta"> {Tutar} </Text>
                                        ) :
                                            <div>{Tutar} </div>
                                        }  </td></tr>
                                    ))
                            }
                        </tbody>
                    </table>

                </Flex>
                {/* <Flex>
                    <form name="myForm">
                        <input style={{height:"13px"}} type="checkbox" name={"myCheck"}
                        //   checked={msfliSati}
                        //   onChange={handleChange} 
                        />
                        <a style={{ fontSize: "12px"}} href='/mesafeli-satis-sozlesmesi' target="_blank" >Mesafeli Satış Sözleşmesini okudum onaylıyorum</a>
                        <br />
                    </form>
                </Flex> */}
                <Flex
                    sx={{
                        alignItems: `start`,
                        justifyContent: `start`
                    }}
                >
                    {/* <Box sx={{ p: 1, width: 250 }}>

                    </Box> */}
                    <Box sx={{
                        p: 4, alignItems: "center", justifyContent: "center", display: "flex",
                        flexDirection: "column", width: "100%"
                    }}>

                        <Button type="submit"
                            aria-label="ödeme tamamla"
                            id={"bnOde"} index={0} >
                            Ödemeyi Tamamla
                        </Button>
                    </Box>
                </Flex>
                <Flex
                    sx={{
                        alignItems: `start`,
                        justifyContent: `start`
                    }}
                >
                    <Text as="span" color="red" style={{ fontSize: "12px" }}> {odemeMesaj} </Text>
                </Flex>
            </StyledCardForm>
        </form>
    );
}
export default WithDefaultContent(CardFormBlock01)














