import React from 'react'
import { graphql } from 'gatsby'
import KtLayoutMobil from '@solid-ui-blocks/KtLayout/KtLayoutMobil'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import Credit from '@solid-ui-blocks/Credit/CreditDesignMobil/Block01'
import { getPricing } from '../../../service/auth'
import KtLog from '../../../../../packages/solid-ui/solid-ui-blocks/src/KtLog/KtLog'
import CanliDestek from '@solid-ui-blocks/CanliDestek/CanliDestek'


const GuvenliOdemeMobil = props => {

  const pageData = getPricing()
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)


  return (
    <KtLayoutMobil prp={props} cnt={content} lightmi={false} >
      <Seo title='Güvenli Ödeme Hızlı üyelik' />
      <Divider space='5' />
      {/* <KtLog> */}
        <Credit content={pageData}></Credit>
      {/* </KtLog> */}
      <Divider space='5' />
      <CanliDestek></CanliDestek>
    </KtLayoutMobil>
  )
}

export const query = graphql`
  query innerpageGuvenliOdemeMobilContent { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`


export default GuvenliOdemeMobil
