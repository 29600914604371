import React, { useEffect, useContext, useState } from 'react'
import { Container, Flex, Box, Badge, Text, Heading, Select, Button } from 'theme-ui'
import Divider from '@solid-ui-components/Divider'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import styled from "styled-components"
import CardFormMobil from '../CardFormMobil/Block01'
import CreditCard from '../CreditCard/Block01'
import { getPricing } from '../../../../../../site/src/service/auth'
import { FormContext } from '@solid-ui-components/ContentForm'
import useForm from '@helpers/useForm'
import httpService from '../../../../../../site/src/service/httpService'



const StyledApp = styled.main`
display: flex;
flex-direction: row;
flex-wrap: wrap;
padding: 1em;
justify-content: space-around;

& > div:not(:last-child) {
  margin-bottom: 2em;
}
`

const CreditDesignBlock01 = ({ content, ...props }) => {

  const { handleSubmit, submitting, success } = useForm()
  const { formValues, setFormValues } = useContext(FormContext)
  const formId = "frmOdeme";




  // setPrice(secilenKredi.Id, secilenKredi.PaketGunSuresi, secilenKredi.Tutar, secilenKredi.IndirmeHakki, Baslik);

  useEffect(() => {
    return () =>
      success !== undefined &&
      submitting === false &&
      setFormValues({
        ...formValues,
        [formId]: {}
      })
  }, [success, submitting, setFormValues])

  const handleChange = e => {
    const { name, value } = e.target;


    setFormValues({
      ...formValues,
      [formId]: {
        ...formValues?.[formId],
        [name]: value,

      }

    })

    setmsfliSati(!msfliSati);
  };


  const initialState = {
    name: "",
    number: "",
    expiryMonth: "",
    expiryYear: "",
    cvv: ""
  };


  const inputReducer = (state, action) => {

    return { ...state, [action.key]: action.value };
  };

  const [cardInfo, handleOnChange] = React.useReducer(
    inputReducer,
    initialState
  );


  const [dt, setDt] = useState(null);
  const [kulId, setKulId] = useState("");

  const [paketAdi, setPaketAdi] = useState("");
  const [tutar, setTutar] = useState("");
  const [msfliSati, setmsfliSati] = useState(false);


  useEffect(async () => {


    const params = new URLSearchParams(window.location.search);

    const pid = params.get("pid");
    const kid = params.get("kid");
    setKulId(kid);
    httpService.post("UnLocked/PaketBilgiMobil", { params: pid }).then((res) => {
      if (res.DogruMu) {
        setDt(res.Data)
        if (res.Data.IndirmeHakki < 100) {
          setPaketAdi(res.Data.IndirmeHakki + " Kredili Paket");
        } else
          setPaketAdi(res.Data.Baslik);
        setTutar(res.Data.Tutar);
      } else {
      
        window.location.assign("/");
        //alert(res.Mesaj);
      }
    }).catch((err) => {
    });

  }, []);

  return (
    <Flex sx={{
      flexWrap: `wrap`,
      alignItems: 'center',
      justifyContent: `center`,
      m: 0, marginTop: -4,
      flexdirection: `column`
    }}>
      <Box>
        {dt && <div style={{
          margin: "5px",
        }}>
          <center> <div><Text as="span" color="black">Seçilen Paket : </Text>
            <Text as="span" color="#8B0000">{paketAdi}  </Text></div></center>
          <Divider space="1" />
          <center>  <div>   <Text as="span" color="black">Çekilecek Tutar : </Text>
            <Text as="span" color="#8B0000" >{tutar}.00₺ (Kdv Dahil)  </Text></div></center>
          <Divider space="3" />

          {/* <center>   <CreditCard cardInfo={cardInfo} /></center> */}
          <Divider space="3" />
          <div style={{ marginLeft: "5%", marginRight: "5%" }}>
            <center> <CardFormMobil cardInfo={cardInfo} paket={dt} onChange={handleOnChange} mesafeli={msfliSati} kulId={kulId} /></center>
          </div>
          <Divider space="1" />
          <center><form name="myForm">
            <input style={{ height: "13px" }} type="checkbox" name={"myCheck"}
              checked={msfliSati}
              onChange={handleChange}
            />
            <a style={{ fontSize: "12px" }} href='/mesafeli-satis-sozlesmesi' target="_blank" >Mesafeli Satış Sözleşmesini okudum onaylıyorum</a>
            <br />
          </form></center>
        </div>
        }
      </Box>
    </Flex>
  )
};



export default WithDefaultContent(CreditDesignBlock01)

